@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@500&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

* {font-family: "Poppins", sans-serif;}
body{color: #212932;}
a:hover,a {text-decoration: none !important;}

h1,h2,h3,h4,h5,h6 {/* font-family: 'Archivo', sans-serif; */font-family: "Poppins", sans-serif;}
h1 {font-size: 60px !important;}
h2 {font-size: 50px !important;}
h3 {font-size: 38px !important;}
h4 {font-size: 30px !important;}
h5 {font-size: 20px !important;}
.font-w-700{font-weight: 700 !important;}
.font-w-400{font-weight: 400 !important;}
.gb_blue {
    background-color: #1F2042;
}

.gb_gray {
    background-color: #ededed;
}

.text-light-blue {
    color: #00509e;
}

.text-white {
    color: #fff;
}

.text-black {
    color: #000;
}

.text-sky {
    color: #27ABC9;
}

.text-blue {
    color: #0560A7;
}

.gb_vadadi {
    background: rgb(15, 101, 170);
    background: linear-gradient(90deg, rgba(15, 101, 170, 1) 0%, rgba(24, 162, 198, 1) 100%);
}

.bg_white {
    background-color: #fff;
}

.radius-10 {
    border-radius: 10px !important;
}

.radius-5 {
    border-radius: 5px !important;
}

.btn-secondary {
    background-color: #00509e !important;
    border-color: #00509e !important;
    color: #fff !important;
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: none !important;
}

input {
    padding: 10px 8px !important;
    height: auto !important;
    border-radius: 10px !important;
}

select {
    padding: 10px 8px !important;
    height: auto !important;
    border-radius: 10px !important;
}

#root {
    background-color: #F4F7FA;
}

section {
    padding: 50px 0px;
}

.c-btn-light {
    background-color: #0560A7;
    color: #fff;
    padding: 12px 25px;
    border-radius: 10px;
    border: 1px solid #0560A7;
    text-decoration: none;
    display: inline-block;
}

.c-btn-light i {
    color: #fff;
}

.c-btn-light:hover,
.c-btn-light:hover i {
    background-color: #fff;
    color: #0560A7;
    text-decoration: none;
    transition: 0.3s all;
}

.c-btn-dark {
    background-color: #202044;
    color: #fff;
    padding: 12px 25px;
    border-radius: 10px;
    border: 1px solid #202044;
    text-decoration: none;
    display: inline-block;
}

.c-btn-dark i {
    color: #fff;
}

.c-btn-dark:hover,
.c-btn-dark:hover i {
    background-color: #fff;
    color: #202044;
    text-decoration: none;
    transition: 0.3s all;
}

.c-btn-sky {
    background-color: #27abc9 !important;
    border: 1px solid #27abc9 !important;
    color: #fff !important;
}

.c-btn-sky:hover {
    background-color: #fff !important;
    color: #27abc9 !important;
}

.c-btn-sky:hover i {
    color: #27abc9 !important;
}

.c-btn-sky-bg-none {
    border: 1px solid #27abc9 !important;
    color: #000 !important;
}

.c-btn-sky-bg-none i {
    color: #27ABC9;
}

.c-btn-blue {
    cursor: auto;
    background-color: #0560a7 !important;
    border: 1px solid #0560a7 !important;
    color: #fff !important;
}

.c-btn-blue-outline {
    cursor: auto;
    background-color: #FFF !important;
    border: 1px solid #0560a7 !important;
    color: #0560a7 !important;
}

.c-btn-blue-outline i {
    color: #0560a7 !important;
}

/* .c-btn-blue:hover{background-color: #fff !important; color: #0560a7 !important;} */
.add_more_btn {
    background: none;
    border: none;
    color: #0560A7;
}

.add_more_btn i {
    color: #0560A7;
}

.btn-outline-dark:hover,
.btn-outline-dark:active,
.btn-outline-dark:focus {
    background-color: #f4f7fa !important;
    box-shadow: none !important;
}

.pointer {
    cursor: pointer;
}

.heade {
    background-color: #FFFFFF;
    padding: 15px 0px;
}

.heade .logo,
footer .logo {
    max-width: 180px;
    max-height: 60px;
}

.heade #Navigation {
    text-align: right;
}

.heade .navbar-nav li a {
    padding: 10px 20px !important;
    color: #545F6C !important;
}

.heade .navbar-nav li a:hover,
.heade .navbar-nav li a.active {
    color: #0560a7 !important;
}

.heade .navbar-nav li a.sign-up {
    background-color: #0560a7;
    color: #fff !important;
    border-radius: 10px;
    border: 1px solid #0560a7;
    margin-right: 20px;
}

@media only screen and (max-width: 992px) {
    .heade .navbar-nav li a.sign-up {
        margin-right: 0px;
        margin-bottom: 20px;
    }
}

.heade .navbar-nav li a.sign-in {
    background-color: #fff;
    color: #0560a7 !important;
    border-radius: 10px;
    border: 1px solid #0560a7;
}

.heade .navbar-nav li.shopping-cart a {
    padding: 7px 7px 4px 7px !important;
}

.heade .navbar-nav li.shopping-cart a i {
    font-size: 25px;
    color: #fff;
}

.fix-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 99;
}

.ready-to-unleash {
    background-image: url('../images/StarBox.png');
    padding: 60px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
}

.ready-to-unleash h2 {
    color: #fff;
    text-align: center;
    margin: 0px auto;
}

.footer {
    padding: 50px 0px;
    background-color: #fff;
    position: relative;
}

.footer ul {
    margin: 0px;
    padding: 0px;
}

.footer .footer-link li {
    list-style: none;
    margin-bottom: 10px;
}

.footer .footer-link li a {
    color: #212932;
    text-decoration: none;
}

.footer .footer-link li a:hover {
    color: #0560a7;
}

.footer .social-media {
    float: right;
}

.footer .social-media li {
    list-style: none;
    margin: 0px 18px 5px 18px;
    display: inline-block;
}

.footer .social-media li img {
    width: 20px;
    height: 20px;
}

.image-box {
    width: 500px;
    height: 650px;
    border-radius: 30px;
    overflow: hidden;
}

.image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.home-banner h2 {
    font-weight: 700;
    letter-spacing: 1px;
}

.why-choose .bg {
    background-color: #0560A7;
    padding: 50px;
    border-radius: 30px;
}

.why-choose img {
    width: 100%;
    border-radius: 30px;
}

.why-choose .text h2 {
    color: #fff;
}

.why-choose .text p {
    color: #fff;
}

.join-us .bg {
    background-color: #1F2042;
    padding: 50px;
    border-radius: 30px;
}

.join-us .card-box {
    background-image: url('../images/bg-card-star.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    height: 100%;
    border-radius: 30px;
}

.join-us .card-box h4 {
    font-size: 32px !important;
}

.join-us .card-box p {
    color: #fff;
}

.features {
    background-image: url('../images//features_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.features h2 {
    color: #fff;
    max-width: 500px;
    margin: 0px auto;
}

.features .features_box {
    padding: 25px;
}

.features .features_box img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
}

.features .features_box h5 {
    color: #27ABC9;
    margin-top: 15px;
    margin-bottom: 15px;
}

.features .features_box p {
    color: #fff;
}

.how_it_works img {
    max-width: 220px;
    width: 100%;
}

.how_it_works h4 {
    color: #00509E;
    margin: 20px 0px;
}

.review .review_box {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
}

.review .review_box img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    margin-right: 10px;
}

.review .review_box h5 {
    color: #0560A7;
    text-transform: capitalize;
    font-size: 16px !important;
    font-weight: 600;
}

.review .review_box .text-muted {
    color: #000 !important;
}

.review .review_box .star i {
    margin-right: 5px;
    font-size: 18px;
}

.pricing h2 {
    max-width: 600px;
    margin: 0px auto;
}

.pricing .price_box {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
}

.pricing .price_box h4{
    font-size: 26px !important;
} 

.pricing .price_box ul {
    list-style-type: none;
    margin-left: 20px;
    padding: 0px;
    min-height: 313px;
}

.pricing .price_box ul li {
    margin-bottom: 12px;
    margin-left: -10px;
    display: flex;
    align-items: center;
}

.pricing .price_box ul li::before {
    color: transparent;
    font-size: 1px;
    content: " ";
    margin-right: 15px;
    padding: 10px;
    background-color: #000;
    -webkit-mask-image: url("./../images/price_right_icon.svg");
    -webkit-mask-size: cover;
}


.faqs .faq {
    border-top: 0px solid #fff;
    padding: 10px 0px;
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
    cursor: pointer;
}

.faqs .faq .faq-question::after {
    content: ">";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
    color: #fff;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
    content: ">";
    transform: rotate(90deg);
    right: 9px;
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}

/* SIGN UP */

.contact-us-form {
    width: 620px;
}

.contact-us h2 {
    font-weight: 700;
    font-size: 36px !important;
}

.contact-us .bg {
    padding: 50px;
    border-radius: 20px;
    background-color: #1F2042;
}

.register-main .bg {
    background-color: #fff;
}

.contact-us label {
    font-size: 14px;
}

.divider {
    display: inline-block;
    width: 32%;
    border-top: 1px solid #ccc;
    margin: 10px 10px;
}

.register-btn {
    border: 1px solid #0560A7;
    border-radius: 10px;
    padding: 10px 0;
    width: 100%;
    background-color: #fff;
}

@media only screen and (max-width: 992px) {
    .contact-us-form h2 {
        font-size: 28px !important;
    }

    .contact-us h2 {
        font-size: 28px !important;
    }

    .contact-us .bg {
        padding: 20px;
    }

    .contact-us {
        padding-top: 20px;
    }

    .white-box {
        padding: 20px 10px !important;
    }

    .forgot-pass h2 {
        font-size: 28px !important;
    }

    .register-btn {
        padding: 9px 0;
    }

    .register-btn img {
        height: 22px !important;
    }
}

textarea {
    resize: none !important;
}

.white-box {
    background-color: #fff;
    padding: 40px;
    border-radius: 25px;
    height: 100%;
}

.white-box .icon {
    background-color: #F4F7FA;
    padding: 15px;
    width: 60px;
    height: 60px;
    border-radius: 39px;
}

.contact-us h2 {
    font-weight: 700;
    font-size: 36px;
}

.white-box .icon img {
    width: 100%;
    max-height: 30px;
}

.plans-table tr td.no-border {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
}

.plans-table,
.plans-table tr {
    border: none;
}

.plans-table tr td img {
    width: 20px;
}

.plans-table tr td,
.plans-table tr,
.plans-table {
    border: 1px solid #707070;
    padding: 10px;
}

.srv-validation-message {
    color: red;
}

input[type="checkbox"] {
    width: 18px;
    height: 18px !important;
}

.breadcrumb {
    background: none !important;
}

.profile_logo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #ced4da;
    position: relative;
    overflow: hidden;
}

.profile_logo img {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

/* custome check box */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #41BFF2;
}

input:focus+.slider {
    box-shadow: 0 0 1px #41BFF2;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.viee_profile {
    padding: 10px;
    min-height: 100vh;
}

.viee_profile .header {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
}

.viee_profile .header img {
    max-height: 200px;
    max-width: 200px;
}

.viee_profile .profile_box {
    margin: 0px auto;
    max-width: 700px;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
}

.viee_profile .tab-nav {
    margin: 10px;
}

.viee_profile .tab-nav a {
    padding: 10px 20px;
}

.viee_profile .tab-nav a.active {
    border-bottom: 1px solid #EF4444;
    display: inline-block;
    color: #EF4444;
}

.viee_profile .link-list {
    width: 100%;
    display: inline-block;
    list-style: none;
}

.viee_profile .link-list li {
    width: 50%;
    float: left;
    margin: 10px 0px;
}

.viee_profile .link-list li .img {
    width: 30px;
    float: left;
    height: 30px;
    margin-right: 10px;
    margin-top: 5px;
}

.viee_profile .link-list li .img img {
    width: 100%;
}

.viee_profile .link-list li a:hover {
    color: #222;
}

.viee_profile .c-btn-primary,
.viee_profile .c-btn-primary:active {
    background-color: #EF4444 !important;
    border-color: #EF4444 !important;
    border-radius: 5px;
}

.profile_menu {
    border-right: 1px solid rgb(233, 234, 233);
}

.profile_menu ul li {
    white-space: nowrap;
    padding: 7px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile_menu ul li a {
    color: rgba(0, 0, 0, .85);
    display: block;
    padding: 6px 0px;
}

.profile_menu ul li a:hover,
.profile_menu ul li .active {
    color: #0560a7;
}

.profile_menu ul li .active {
    border-right: 2px solid #0560a7;
}

.profile_menu ul li span {
    margin-left: 10px;
}

.premium {
    display: none;
}

.box-disble {
    background-color: #f0f0f0;
    cursor: default;
}

.box-disble .premium {
    position: absolute;
    right: 40px;
    font-size: 12px;
    display: block;
}

table.review tr td {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.full_screen_loader {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 99;
    background-color: #eaeaea57;
}

.full_screen_loader .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
}

.header-social {
    position: absolute;
    right: 0px;
    top: -6px;
}

.Image_man_box {
    border: 1px dashed rgb(205, 207, 206);
    border-radius: 8px;
    padding: 15px;
    display: inline-block;
    width: 100%;
    overflow: auto;
    height: 425px;
}

.Image_man_box .img_box {
    width: 170px;
    height: 170px;
    float: left;
    margin: 10px;
    border: 1px solid #ededed;
    padding: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
}

.Image_man_box .img_box span {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 0px 5px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #202044;
    border-radius: 5px;
    color: #202044;
}

.Image_man_box .img_box.add {
    cursor: pointer;
    border: 1px dashed #202044;
}

.Image_man_box.no-border {
    border: none;
    border-radius: 0px;
    padding: 0px;
    height: auto;
}

.Image_man_box.no-border .img_box {
    margin-left: 0px;
}

.profil-right-scroll {
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.tab_menu {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: inline-block;
    width: 100%;
}

.tab_menu li {
    line-height: 28px;
    float: left;
    padding: 5px;
    margin-right: 25px;
}

.tab_menu li span {
    border-bottom: 1px solid #f4f7fa;
    padding-bottom: 3px;
    font-weight: 600;
}

.tab_menu li.active span {
    border-color: #0560A7;
    color: #0560A7;
}
.miniwebsite-header li a.c-btn-blue {
    border: 1px solid #0560A7;
    background-color: #0560A7;
    border-radius: 5px;
    color: #fff !important;
}

.miniwebsite-header li a.c-btn-blue:hover {
    border: 1px solid #0560A7;
    background-color: #fff !important;
    color: #0560A7 !important;
}

.progress-line {
    width: 100%;
    display: inline-block;
}

.progress-line .title {
    width: 40px;
    float: left;
}

.progress-line .progress {
    width: calc(100% - 65px);
    float: left;
    margin-top: 8px;
}

.progress-line .count {
    float: left;
    width: 25px;
    text-align: right;
}

.services_box {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #D2D2D2;
}

.services_box p {
    font-size: 14px;
}

.gallery .img_box {
    border-radius: 10px;
    overflow: hidden;
}

.gallery .img_box img {
    max-width: 100%;
}

.contact_box {
    display: inline-block;
    width: 100%;
}

.contact_box .icon {
    width: 35px;
    float: left;
    text-align: center;
    padding-top: 10px;
}

.contact_box .text {
    width: calc(100% - 35px);
    float: left;
    padding-left: 10px;
}

.miniwebsite-header .navbar .mobile-btn {
    display: none;
}

@media only screen and (max-width: 992px) {
    header .navbar-collapse ul {
        text-align: center;
    }

    header .navbar-collapse ul li {
        margin: 0px auto;
    }

    .footer .social-media {
        float: unset;
        text-align: center;
        margin-top: 30px;
    }

    h1 {
        font-size: 2.5rem !important;
    }

    h2 {
        font-size: 2rem !important;
    }

    h3 {
        font-size: 1.75rem !important;
    }

    h4 {
        font-size: 1.5rem !important;
    }

    h5 {
        font-size: 1.25rem !important;
    }

    .header-social {
        display: none;
    }

    .miniwebsite-header ul li a {
        display: block;
    }

    .miniwebsite-header li a.c-btn-blue {
        display: none;
    }

    .miniwebsite-header .navbar .mobile-btn {
        display: block;
    }

    .navbar {
        justify-content: unset !important;

    }

    .miniwebsite-header .mobile-logo {
        width: calc(100% - 220px);
    }

    .miniwebsite-header .mobile-logo img {
        max-width: 100%;
    }

    .tab_menu li {
        display: none;
    }

    .tab_menu li.active,
    .tab_menu li.show {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .business-details .border-right {
        border: none !important;
    }

    .profil-right-scroll {
        max-height: none;
    }
}

@media only screen and (max-width: 600px) {
    .viee_profile .link-list li {
        width: 100%;
    }
}

@media only screen and (max-width: 576px) {
    .c-btn-block {
        width: 100%;
    }
}

/* PROCESS */
.process h2 {
    font-weight: 700;
    font-size: 36px !important;
}

.process-img img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

.process .process-card-img-body {
    background-color: #202044;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.process .process-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    min-height: 440px;
    background-color: #fff;
}

.process .process-card-title h3 {
    font-size: 26px !important;
    color: #0560A7;
    font-weight: 700;
}

.process-line label {
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 0px 8px;
    margin: 0;
}

.process-line {
    display: flex;
    align-items: center;
}

.process-line span {
    margin-top: -7px;
}

.process-line label::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 87%;
    border-top: 1px dotted #000;
    margin-top: 12px;
}

@media only screen and (max-width: 768px) {
    .image-box {
        height: 300px;
    }

    .home-banner {
        padding-top: 20px;
    }

    .navbar-brand img {
        height: 25px !important;
    }
}

@media only screen and (max-width: 992px) {
    .process-body {
        display: flex;
        align-items: start;
    }

    .process-line {
        margin-right: 10px;
    }

    .process-line label::after {
        content: "";
        position: absolute;
        top: 15px;
        left: 26px;
        width: 1px;
        margin-top: 12px;
        height: 93%;
        background-color: #ccc;
    }

    .process h2 {
        font-size: 28px !important;
    }

    .process {
        padding-top: 0 !important;
    }

    .process .process-card-title h3 {
        font-size: 22px !important;
    }

    .navbar-brand {
        margin-left: 10px !important;
    }
}

.sign-up-btn-show {
    display: none !important;
}

/* OFFERS */

.offers .bg {
    background-color: #0560A7;
    padding: 50px 0;
    background-image: url("../images/offer_bg.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.offers h2 {
    font-weight: 700;
    font-size: 36px !important;
}

.offer-box-icon {
    height: 40px;
}

.offer-box-icon img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

.offer-box {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 15px;
    height: 100%;
}

.offer-box-title h4 {
    font-size: 24px !important;
    color: #0560A7;
}

.offer-box-para p {
    font-size: 15px;
}

@media only screen and (max-width: 992px) {
    .offers h2 {
        font-size: 28px !important;
    }

    .offer-box-title h4 {
        font-size: 20px !important;
    }

    .offers {
        padding-top: 0;
    }

    .sign-up-btn-show {
        display: block !important;
    }

    .sign-up-btn-hide {
        display: none !important;
    }
}

/* WHY BUSINESS */

.why-business-img img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

.why-businesses h2 {
    font-weight: 700;
    font-size: 36px !important;
}

.why-businesses h3 {
    font-weight: 700;
    font-size: 28px !important;
}

@media only screen and (max-width: 992px) {
    .why-businesses h2 {
        font-size: 28px !important;
    }

    .why-businesses h3 {
        font-size: 22px !important;
    }

    .why-businesses {
        padding-top: 0;
    }
}

/* LATEST NEWS */

.news h2 {
    font-weight: 700;
    font-size: 36px !important;
}

.news-body {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 12px;
}

.news-img img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;
}

.news-title h4 {
    font-size: 23px !important;
    font-weight: 700;
}

.news-action span {
    border: 1px solid #949494;
    padding: 4px 3px;
    border-radius: 20px;
    color: #949494;
    font-size: 12px;
}

.news-action label {
    font-size: 14px;
}

.news-btn button {
    border: 1px solid #0560A7;
    color: #0560A7;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
}

.news-btn button:hover {
    color: #fff;
    background-color: #0560A7;
    transition: 0.3s all;
}

@media only screen and (max-width: 992px) {
    .news h2 {
        font-size: 28px !important;
    }

    .news {
        padding-top: 0 !important;
    }

    .news-title h4 {
        font-size: 20px !important;
    }
}

/* SLIDER */

.client-reviews {
    margin: 20px;
}

.client-reviews h2 {
    text-align: center;
    margin-bottom: 20px;
}

.slide {
    padding: 10px;
    height: 100%;
}

.review-box {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.review-box h3 {
    margin-bottom: 10px;
}

.review-box p {
    color: #333;
}

.review-box a {
    text-decoration: underline !important;
    font-weight: 600;
}

.slick-track {
    display: flex !important;
}

.slick-list {
    overflow-x: hidden !important;
}

.review-box h4 {
    font-size: 20px !important;
    color: #0560A7;
}

.reviews-rating {
    height: 25px;
}

.reviews-rating img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

.slick-slide>div:first-child {
    height: 100% !important;
}

.client-reviews h2 {
    font-weight: 700;
    font-size: 36px !important;
}


@media only screen and (max-width: 992px) {
    .review-box h4 {
        font-size: 18px !important;
    }

    .client-reviews h2 {
        font-size: 28px !important;
    }

    .client-reviews {
        padding-top: 0;
    }
}

/* SUBSCRIBE FORM */

.subscribe .bg {
    background-color: #202044;
    padding: 50px 0;
    background-image: url("../images/subscribe_bg.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.subscribe h2 {
    font-weight: 700;
    font-size: 36px !important;
}

.bg_image{
    background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: 100vh;
  overflow: auto;
  z-index: 0;
  position: fixed;
  width: 100%;
  opacity: 0.8;
}
@media only screen and (max-width: 992px) {
    .subscribe h2 {
        font-size: 28px !important;
    }

    .subscribe {
        padding-top: 0;
    }
}