.Widget1{max-width: 300px;margin: 0px auto;display: flex;width: 100%; padding: 15px 5px;}
.Widget1 .logo{width: 100px;float: left;}
.Widget1 .logo img{width: 100%;margin-top: 9px;}
.Widget1 .revie_box{padding-left: 15px;width: calc(100% - 100px);}
.Widget1 .revie_box .rating{width: 100%;display: inline-block;margin-bottom: -15px;}
.Widget1 .revie_box .count{float: left;width: 35px;}
.Widget1 .title{margin-bottom: 5px;}
.Widget1 .icone{width: 100%;display: inline-block;margin-bottom: -9px;float: left;width: calc(100% - 35px);}
.Widget1 .icone i{font-size: 22px;padding: 0px 3.7px}
.Widget1 a{font-size: 11px; color: #868686;}

.Widget1 .stars{position: relative; color: #B6B9C1;}
.Widget1 .stars .filled{position: absolute;top: 0;left: 0;overflow: hidden;white-space: nowrap;color: #ffc107;}

.Widget2 {max-width: 150px; margin: 0px auto;padding: 15px 5px;}
.Widget2 img{max-width: 100%;}
.Widget2 .icone i{font-size: 22px;padding: 0px 3.7px}

.Widget2 .stars{position: relative; color: #B6B9C1;max-width: 140px;}
.Widget2 .stars .filled{position: absolute;top: 0;left: 0;overflow: hidden;white-space: nowrap;color: #ffc107;}
.Widget2 a{font-size: 11px; color: #868686;}